import React from 'react'
import styles from 'styles/components/organisms/howToUse.module.scss'
import {
  IconCalculator,
  IconCar2,
  IconChevronRight,
  IconFast,
} from 'components/atoms/icon'
import urls from 'utils/helpers/url'
import elementId from 'utils/helpers/trackerId'
import { sendAmplitudeData } from 'services/amplitude'
import { AmplitudeEventName } from 'services/amplitude/types'
import {
  navigateToPLP,
  PreviousButton,
  saveDataForCountlyTrackerPageViewLC,
} from 'utils/navigate'
import { trackLPHowToUseSevaClick } from 'services/amplitude/seva20Tracking'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { getToken } from 'utils/handler/auth'
import {
  carResultsUrl,
  loanCalculatorDefaultUrl,
  multiCreditQualificationPageUrl,
} from 'utils/helpers/routes'
import { useFunnelQueryData } from 'services/context/funnelQueryContext'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import Link from 'next/link'

type HowToUseLPProps = {
  onCtaClickKK: () => void
  pageOrigination: string
}
const HowToUse = ({ onCtaClickKK, pageOrigination }: HowToUseLPProps) => {
  const router = useRouter()
  const { clearQueryFilter } = useFunnelQueryData()
  const isOnSuccessPage = router.pathname.includes('/landing-promo')
  const onClickDreamCar = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    clearQueryFilter()
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_CAR_SEARCH_BUTTON_CLICK, {
      SOURCE_SECTION: 'SEVA Steps',
      CAR_BRAND: 'Null',
      CAR_TYPE: 'Null',
      MIN_PRICE: 'Null',
      MAX_PRICE: 'Null',
      DP_AMOUNT: 'Null',
      TENOR_OPTION: 'Null',
      INCOME_AMOUNT: 'Null',
      AGE_RANGE: 'Null',
      PRODUCT_TYPE: 'Mobil Baru',
      PAGE_ORIGINATION: pageOrigination,
    })
    // trackLPHowToUseSevaClick({
    //   Page_Direction_URL: 'https://' + window.location.host + carResultsUrl,
    // })
    if (e.button === 0 && e.ctrlKey) {
      window.open('/mobil-baru', '_blank')
    } else {
      navigateToPLP(PreviousButton.SevaSteps, {}, true, false, undefined, false)
    }
  }

  const onClickDreamCarMiddleRightClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.button === 1 || e.button === 2) {
      clearQueryFilter()
      trackEventCountly(
        CountlyEventNames.WEB_HOMEPAGE_CAR_SEARCH_BUTTON_CLICK,
        {
          SOURCE_SECTION: 'SEVA Steps',
          CAR_BRAND: 'Null',
          CAR_TYPE: 'Null',
          MIN_PRICE: 'Null',
          MAX_PRICE: 'Null',
          DP_AMOUNT: 'Null',
          TENOR_OPTION: 'Null',
          INCOME_AMOUNT: 'Null',
          AGE_RANGE: 'Null',
          PRODUCT_TYPE: 'Mobil Baru',
          PAGE_ORIGINATION: pageOrigination,
        },
      )
    }
  }

  const onClickCalculate = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_LOAN_CALCULATOR_CLICK, {
      SOURCE_SECTION: 'SEVA steps',
      CAR_BRAND: 'Null',
      CAR_MODEL: 'Null',
      CAR_ORDER: 'Null',
      PAGE_ORIGINATION: pageOrigination,
    })
    saveDataForCountlyTrackerPageViewLC(PreviousButton.SevaStepsCalculate)
    if (e.button === 0 && e.ctrlKey) {
      window.open(`${loanCalculatorDefaultUrl}?from=homepageHitung`, '_blank')
    } else {
      window.location.href = `${loanCalculatorDefaultUrl}?from=homepageHitung`
    }
  }

  const onClickCalculateMiddleRightClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.button === 1 || e.button === 2) {
      trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_LOAN_CALCULATOR_CLICK, {
        SOURCE_SECTION: 'SEVA steps',
        CAR_BRAND: 'Null',
        CAR_MODEL: 'Null',
        CAR_ORDER: 'Null',
        PAGE_ORIGINATION: pageOrigination,
      })
      saveDataForCountlyTrackerPageViewLC(PreviousButton.SevaStepsCalculate)
    }
  }

  const onClickCreditQualification = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    trackEventCountly(
      CountlyEventNames.WEB_HOMEPAGE_CHECK_CREDIT_QUALIFICATION_CLICK,
      {
        SOURCE_SECTION: 'SEVA steps',
        LOGIN_STATUS: !!getToken() ? 'Yes' : 'No',
        PAGE_ORIGINATION: pageOrigination,
      },
    )
    trackLPHowToUseSevaClick({
      Page_Direction_URL:
        'https://' + window.location.host + loanCalculatorDefaultUrl,
    })
    saveDataForCountlyTrackerPageViewLC(PreviousButton.SevaStepsQualification)
    if (e.button === 0 && e.ctrlKey) {
      window.open(multiCreditQualificationPageUrl, '_blank')
    } else {
      onCtaClickKK()
    }
  }

  const onClickCreditQualificationMiddleRightClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.button === 1 || e.button === 2) {
      trackEventCountly(
        CountlyEventNames.WEB_HOMEPAGE_CHECK_CREDIT_QUALIFICATION_CLICK,
        {
          SOURCE_SECTION: 'SEVA steps',
          LOGIN_STATUS: !!getToken() ? 'Yes' : 'No',
          PAGE_ORIGINATION: pageOrigination,
        },
      )
      saveDataForCountlyTrackerPageViewLC(PreviousButton.SevaStepsQualification)
    }
  }

  return (
    <div
      className={clsx({
        [styles.wrapper]: !isOnSuccessPage,
        [styles.wrapperSuccesPage]: isOnSuccessPage,
      })}
    >
      <h2 className={styles.headerText} style={{ width: '75%' }}>
        Cara Dapatkan Mobil Impian di SEVA
      </h2>
      <div className={styles.cardSpacing}>
        <Link
          href="/mobil-baru"
          className={styles.cardHowToUse}
          onClick={onClickDreamCar}
          onMouseDown={onClickDreamCarMiddleRightClick}
          data-testid={elementId.Homepage.PilihMobilImpian}
        >
          <div>
            <h3>
              <p className={styles.cardTextBold}>Pilih Mobil </p>
              <div className={styles.row}>
                <p className={styles.headerText}>Impian</p>
                <IconCar2 width={24} height={24} color={'#B4231E'} />
              </div>
            </h3>
            <div className={styles.row}>
              <p className={styles.textGrey}>
                Pilih mobil impian kamu dari berbagai merek dengan jaminan
                kualitas Astra.
              </p>
            </div>
          </div>
          <div>
            <IconChevronRight width={24} height={24} color={'#05256E'} />
          </div>
        </Link>
        <Link
          href={`${loanCalculatorDefaultUrl}?from=homepageHitung`}
          className={styles.cardHowToUse}
          onClick={onClickCalculate}
          onMouseDown={onClickCalculateMiddleRightClick}
          data-testid={elementId.Homepage.HitungKemampuan}
        >
          <div>
            <h3>
              <p className={styles.cardTextBold}>Hitung </p>
              <div className={styles.row}>
                <p className={styles.headerText}>Kemampuan</p>
                <IconCalculator width={24} height={24} color={'#B4231E'} />
              </div>
            </h3>
            <div className={styles.row}>
              <p className={styles.textGrey}>
                Dapatkan hasil perhitungan cicilan mobil yang sesuai dengan
                kemampuan finansialmu!
              </p>
            </div>
          </div>
          <div>
            <IconChevronRight width={24} height={24} color={'#05256E'} />
          </div>
        </Link>
        <Link
          href={multiCreditQualificationPageUrl}
          className={styles.cardHowToUse}
          onClick={onClickCreditQualification}
          onMouseDown={onClickCreditQualificationMiddleRightClick}
          data-testid={elementId.Homepage.KualifikasiKredit}
        >
          <div>
            <h3>
              <div className={styles.row} style={{ marginBottom: '0px' }}>
                <p className={styles.cardTextBold}>Kualifikasi </p>
                <IconFast width={24} height={24} color={'#B4231E'} />
              </div>
              <div className={styles.row}>
                <p className={styles.headerText}>Kredit</p>
              </div>
            </h3>
            <div className={styles.row}>
              <p className={styles.textGrey}>
                Cek kualifikasi kredit kamu sebelum mengajukan pinjaman mobil.
              </p>
            </div>
          </div>
          <div>
            <IconChevronRight width={24} height={24} color={'#05256E'} />
          </div>
        </Link>
      </div>
    </div>
  )
}

export default HowToUse
