import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconFast: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.42008 9.42392C5.98018 8.85869 6.88829 8.85869 7.44839 9.42392L17.9285 20L7.44839 30.5761C6.88829 31.1413 5.98018 31.1413 5.42008 30.5761C4.85997 30.0108 4.85997 29.0944 5.42008 28.5292L13.8719 20L5.42008 11.4708C4.85997 10.9056 4.85997 9.98916 5.42008 9.42392ZM14.886 9.42392C15.4461 8.85869 16.3542 8.85869 16.9143 9.42392L27.3944 20L16.9143 30.5761C16.3542 31.1413 15.4461 31.1413 14.886 30.5761C14.3259 30.0108 14.3259 29.0944 14.886 28.5292L23.3378 20L14.886 11.4708C14.3259 10.9056 14.3259 9.98916 14.886 9.42392ZM25.5197 9.42392C24.9596 8.85869 24.0515 8.85869 23.4914 9.42392C22.9313 9.98916 22.9313 10.9056 23.4914 11.4708L31.9432 20L23.4914 28.5292C22.9313 29.0944 22.9313 30.0108 23.4914 30.5761C24.0515 31.1413 24.9596 31.1413 25.5197 30.5761L35.9999 20L25.5197 9.42392Z"
        fill={color}
      />
    </svg>
  )
}
